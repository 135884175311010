import { urls } from "@/urls";

export const demoBotRoutes = {
  async lazy() {
    const { Root } = await import("./Root");
    return { Component: Root };
  },
  children: [
    {
      path: urls.demo.chat,
      async lazy() {
        const { DemoChat } = await import("./DemoChat");
        return { Component: DemoChat };
      },
    },
    {
      path: urls.demo.reference_image,
      async lazy() {
        const { DemoReferenceImage } = await import("./DemoReferenceImage");
        return { Component: DemoReferenceImage };
      },
    },
    {
      path: urls.demo.design_brief,
      async lazy() {
        const { DemoDesignBrief } = await import("./DemoDesignBrief");
        return { Component: DemoDesignBrief };
      },
    },
  ],
};
