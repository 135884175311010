export const urls = {
  login: "/login",
  forget_password: "/forget_password",
  registration: "/registration",
  plan: "/choosing_plan",
  interior_plan: "/choosing_interior_plan",
  demo: {
    chat: "/genie_demo/chat",
    design_brief: "/genie_demo/design_brief/:identifyKey",
    reference_image: "/genie_demo/reference_image/:identifyKey",
    mood_board: "/genie_demo/mood_board",
  },
  design_genie: {
    home: "/genie/:userLinkWords",
    chat: "/genie/:userLinkWords/chat",
    reference_image: "/genie/:userLinkWords/reference_image/:identifyKey",
    design_brief: "/genie/:userLinkWords/design_brief/:identifyKey",
    book: "/genie/book",
    thank_you: "/genie/:userLinkWords/thank_you",
  },
  admin: {
    dashboard: "/client_request",
    client: "/client_request/:clientId",
    shared_overview: "/client_request/:identifyKey/overview",
    shared_suggestions: "/client_request/:identifyKey/suggestions",
    shared_history: "/client_request/:identifyKey/history",
    account: "/account",
    account_info_edit: "/account/info/edit",
    account_password_edit: "/account/password/edit",
    demo: "/client_request_demo",
    demo_shared_overview: "/client_request_demo/overview",
    demo_shared_suggestions: "/client_request_demo/suggestions",
    demo_shared_history: "/client_request_demo/history",
  },
  embed: {
    home: "/genie-embed/:userLinkWords/",
    chat: "/genie-embed/:userLinkWords/chat",
  },
  outer_plan: {
    normal_design: "/plan",
    interior_design: "/interior_plan",
  },
  mood_board: {
    home: "/mood_board/:userLinkWords",
    chat: "/mood_board/:userLinkWords/chat",
    reference_image: "/mood_board/:userLinkWords/reference_image/:identifyKey",
    design_brief: "/mood_board/:userLinkWords/design_brief/:identifyKey",
    thank_you: "/mood_board/:userLinkWords/thank_you",
  },
  embed_mood_board: {
    home: "/mood-board-embed/:userLinkWords",
    chat: "/mood-board-embed/:userLinkWords/chat",
    reference_image:
      "/mood-board-embed/:userLinkWords/reference_image/:identifyKey",
    design_brief: "/mood-board-embed/:userLinkWords/design_brief/:identifyKey",
    thank_you: "/mood-board-embed/:userLinkWords/thank_you",
  },
  moodBoard: "/mood_board/:userLinkWords",
  moodBoardv2: "/mood_board_v2/:userLinkWords",
  moodBoardv3: "/mood_board_v3/:userLinkWords",
};
