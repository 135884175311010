import { urls } from "@/urls";

export const embedMoodBoardRoutes = {
  async lazy() {
    const { Root } = await import("./Root");
    return { Component: Root };
  },
  children: [
    {
      path: urls.embed_mood_board.home,
      async lazy() {
        const { Home } = await import("./Home");
        return { Component: Home };
      },
    },
    {
      path: urls.embed_mood_board.chat,
      async lazy() {
        const { Chat } = await import("./Chat");
        return { Component: Chat };
      },
    },
    {
      path: urls.embed_mood_board.reference_image,
      async lazy() {
        const { ReferenceImage } = await import("./ReferenceImage");
        return { Component: ReferenceImage };
      },
    },
    {
      path: urls.embed_mood_board.design_brief,
      async lazy() {
        const { DesignBrief } = await import("./DesignBrief");
        return { Component: DesignBrief };
      },
    },
    {
      path: urls.embed_mood_board.thank_you,
      async lazy() {
        const { ThankYou } = await import("./ThankYou");
        return { Component: ThankYou };
      },
    },
  ],
};
