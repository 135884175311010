import { urls } from "@/urls";

export const demoMoodBoardRoutes = {
  async lazy() {
    const { Root } = await import("./Root");
    return { Component: Root };
  },
  children: [
    {
      path: urls.demo.mood_board,
      async lazy() {
        const { DemoMoodBoard } = await import("./DemoMoodBoard");
        return { Component: DemoMoodBoard };
      },
    },
  ],
};
