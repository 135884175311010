import { urls } from "@/urls";

export const loginRoutes = {
  path: urls.login,
  async lazy() {
    const { Login } = await import("./Login");
    return { Component: Login };
  },
};

export const registrationRoutes = {
  path: urls.registration,
  async lazy() {
    const { Registration: SignUp } = await import("./Registration");
    return { Component: SignUp };
  },
};

export const planRoutes = {
  path: urls.plan,
  async lazy() {
    const { Plan } = await import("./Plan");
    return { Component: Plan };
  },
};

export const interiorPlanRoutes = {
  path: urls.interior_plan,
  async lazy() {
    const { InteriorPlan } = await import("./InteriorPlan");
    return { Component: InteriorPlan };
  },
};

export const forgetPasswordRoutes = {
  path: urls.forget_password,
  async lazy() {
    const { ForgetPassword } = await import("./ForgetPassword");
    return { Component: ForgetPassword };
  },
};
