/* eslint-disable no-useless-catch */
import { FetchError } from "@/utils/error";

export type TPlan = {
  id: number;
  name: string;
  description: string;
  thirdparty_id: string | null;
};

export type TUser = {
  id: number;
  username: string;
  display_name: string;
  link_words: string;
  profile_image: string;
  organization: string | null | undefined;
  chatbot_id: string | null | undefined;
  language: string;
  subscription?: {
    start_at: string;
    end_at: string;
  };
  plan?: TPlan | null;
};

export type LoginData = {
  username: string;
  password: string;
};

export type RegisterData = {
  genie_name: string;
  username: string;
  password: string;
};

export const UserAPI = {
  login: async (data: LoginData) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/login/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );

      const res = await response.json();
      if (res.token) {
        return res.token;
      }
      throw new FetchError(response);
    } catch (error) {
      throw error;
    }
  },
  register: async (data: RegisterData) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/register/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );

      const res = await response.json();
      if (res.token) {
        return res.token;
      }
      throw new FetchError(response);
    } catch (error) {
      throw error;
    }
  },
  checkDuplicateGenieName: async (genieName: string) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/info_duplicate_check/?genie_name=${genieName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const res = await response.json();
      if (res.status == "ok") {
        return true;
      } else if (res.error || res.status == "bad_request") {
        return false;
      }
      throw new FetchError(response, res.msg);
    } catch (error) {
      throw error;
    }
  },
  checkDuplicateUsername: async (username: string) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/info_duplicate_check/?username=${username}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const res = await response.json();
      if (res.status == "ok") {
        return true;
      } else if (res.error || res.status == "bad_request") {
        return false;
      }
      throw new FetchError(response, res.msg);
    } catch (error) {
      throw error;
    }
  },
  getUserByLinkWords: async (linkWords: string | undefined) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/designer/${linkWords}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const res = await response.json();
      if (res.success) {
        return {
          ...res.data,
          plan: res.plan as TPlan | null,
        } as TUser;
      }
      throw new FetchError(response, res.msg);
    } catch (error) {
      throw error;
    }
  },
  getUser: async (): Promise<TUser | undefined> => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/personal_info/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("site") || new URL(window.location.href).searchParams.get("token")}`,
          },
        },
      );

      const res = await response.json();
      if (res.success) {
        return {
          ...res.data,
          subscription: res.subscription,
        } as TUser;
      }
      throw new FetchError(response, res.msg);
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (data: FormData) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/personal_info/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("site")}`,
          },
          body: data,
        },
      );

      const res = await response.json();
      if (res.success) return;
      throw new FetchError(response, res.msg);
    } catch (error) {
      throw error;
    }
  },
  updatePassword: async (data: FormData) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/change_password/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("site")}`,
          },
          body: data,
        },
      );

      const res = await response.json();
      if (res.success) return;
      throw new FetchError(response, res.msg);
    } catch (error) {
      throw error;
    }
  },
  getCreditUsage: async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/my/credit_usage/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("site")}`,
          },
        },
      );

      const res = await response.json();
      if (response.ok) {
        return res.message_count as number;
      }
      throw new FetchError(response, res.msg);
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (email: string) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/forget_password/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        },
      );

      if (response.ok) return;
      throw new FetchError(response);
    } catch (error) {
      throw error;
    }
  },
};
