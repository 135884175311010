import { urls } from "@/urls";

export const outerPlanRoutes = {
  path: urls.outer_plan.normal_design,
  async lazy() {
    const { OuterPlan } = await import("./OuterPlan");
    return { Component: OuterPlan };
  },
};

export const outerInteriorPlanRoutes = {
  path: urls.outer_plan.interior_design,
  async lazy() {
    const { OuterInteriorPlan } = await import("./OuterInteriorPlan");
    return { Component: OuterInteriorPlan };
  },
};
