import { urls } from "@/urls";

export const moodBoardRoutes = {
  async lazy() {
    const { Root } = await import("./Root");
    return { Component: Root };
  },
  children: [
    {
      path: urls.mood_board.home,
      async lazy() {
        const { MoodBoardHome } = await import("./MoodBoardHome");
        return { Component: MoodBoardHome };
      },
    },
    {
      path: urls.mood_board.chat,
      async lazy() {
        const { MoodBoardChat } = await import("./MoodBoardChat");
        return { Component: MoodBoardChat };
      },
    },
    {
      path: urls.mood_board.reference_image,
      async lazy() {
        const { MoodBoardReferenceImage } = await import(
          "./MoodBoardReferenceImage"
        );
        return { Component: MoodBoardReferenceImage };
      },
    },
    {
      path: urls.mood_board.design_brief,
      async lazy() {
        const { MoodBoardDesignBrief } = await import("./MoodBoardDesignBrief");
        return { Component: MoodBoardDesignBrief };
      },
    },
    {
      path: urls.mood_board.thank_you,
      async lazy() {
        const { MoodBoardThankYou } = await import("./MoodBoardThankYou");
        return { Component: MoodBoardThankYou };
      },
    },
  ],
};

export const moodBoardV2Routes = {
  path: urls.moodBoardv2,
  async lazy() {
    const { MoodBoardV2 } = await import("./MoodBoardV2");
    return { Component: MoodBoardV2 };
  },
};

export const moodBoardV3Routes = {
  path: urls.moodBoardv3,
  async lazy() {
    const { MoodBoardV3 } = await import("./MoodBoardV3");
    return { Component: MoodBoardV3 };
  },
};
